.Versionselector{
	position: fixed;
	width:100%;
	height:100%;
	background-color: rgba(0,0,0,.9);
	left:0;
	top:0;
	z-index: 99999;
}

.Versionselector .content{
	border: 2px solid var(--red);
	background-color: var(--red-semi);
	color:var(--white);
	font-weight: lighter;
	margin:auto;
	width:500px;
	margin-top:10%;
	padding: 10px;
	position: relative;
}


.Versionselector span{color:gray}
.Versionselector .Versionselectorlist
{

}

.Versionselector .Versionselectorlist>div
{
	cursor: pointer;
	margin-top:10px;
	padding:3px;
}

.Versionselector .Versionselectorlist>div.active
{
	color:white;
	font-weight: bold;
}
.Versionselector .Versionselectorlist>div:hover
{
	color:gray
}

.Versionselector small{opacity: 0.6;position: absolute;right:5px;top:5px;}