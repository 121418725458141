body {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
	user-select: none;
}

body {
	--white:rgba(242, 240, 255,1);
	--gray:#85868c;
	--gray-light:#bbbac1;

	--black:#000;
	--red:rgba(140,25,32,1);
	--red-semi:rgba(140,25,32,.5);
	--red-mid:rgba(91,26,32,1);
	--red-dark:rgba(66,25,31,1);
	--dark:rgba(44,46,50,1);
	--dark-semi:rgba(44,46,50,.95);
	--dark-semi2:rgba(44,46,50,0.7);
	--dark-mid:rgba(44,46,50,0.5);
	--green:rgba(20,125,18,1);
	--green-semi:rgba(20,125,18,.5);
}

.App {

}

.noselect{
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn_highlight{position: absolute;top:0;left:0;height:100%;width: 100%;pointer-events: none;background-color:transparent}

.active .btn_highlight{	animation-name: heighlight;animation-duration: .6s;}

@keyframes heighlight {
	from { background-color: white; }
		to { background-color: transparent; }
}


.disabled{pointer-events: none;opacity: .5;}

.hidden{display:none !important;}

@-webkit-keyframes fadeIn {
	from { opacity: 0 !important; }
		to { opacity: 1 !important; }
}
@keyframes fadeIn {
	from { opacity: 0; }
		to { opacity: 1; }
}

*{ animation: .5s ease-out 0s 1 fadeIn;}

.nr, i{animation-duration: 0s!important;}

._nr{word-spacing:-2px;white-space: nowrap;}
.nr{word-spacing:-.5ex;white-space: nowrap;}
._3_4_5_2 .nr{word-spacing:-.3ex;}
._4_1_1_5 .nr{word-spacing:-.3ex;}


html, body{width:100%;height:100%;margin:0;padding:0;overflow: hidden;}
#root{height:100%;}
.App{width:100%;height:100%;position:absolute;}

.App{width:var(--app_w);height:var(--app_h);transform: scale(var(--scale));transform-origin: top left;background-color: #2c2e32}
